// @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);
/* line 1, ../../frontend/scss/_base.scss */
// body {
//   font-family: "Open Sans", sans-serif;
// }

/* line 6, ../../frontend/scss/_base.scss */
// h1, h2, h3, h4, h5, h6 {
//   font-family: "Open Sans", "é»‘é«”-ç¹", "Heiti TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
// }

/* line 11, ../../frontend/scss/_base.scss */
a:hover, a:link {
  text-decoration: none;
}

/* line 16, ../../frontend/scss/_base.scss */
.align-right {
  text-align: right !important;
}

/* line 20, ../../frontend/scss/_base.scss */
.align-center {
  text-align: center !important;
}

// /* line 24, ../../frontend/scss/_base.scss */
// .navbar {
//   font-family: "Open Sans", "é»‘é«”-ç¹", "Heiti TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
// }
// /* line 27, ../../frontend/scss/_base.scss */
// .navbar .brand {
//   height: 40px;
//   padding: 0 20px;
//   color: #555;
// }
// /* line 32, ../../frontend/scss/_base.scss */
// .navbar .brand img {
//   float: left;
// }
// /* line 36, ../../frontend/scss/_base.scss */
// .navbar .brand .text {
//   padding: 10px 0;
//   float: left;
// }
// /* line 42, ../../frontend/scss/_base.scss */
// .navbar .nav > li > a {
//   color: #555;
// }
// /* line 46, ../../frontend/scss/_base.scss */
// .navbar .nav-subscript {
//   font-size: 11px;
//   margin-left: 3px;
//   color: red;
//   line-height: 1;
// }

/* line 55, ../../frontend/scss/_base.scss */
super.plus {
  font-size: 75%;
  position: relative;
  top: -0.4em;
  margin-left: 0.1em;
}

/* line 62, ../../frontend/scss/_base.scss */
.col-logo {
  padding-left: 0;
}
/* line 65, ../../frontend/scss/_base.scss */
.col-logo .logo a {
  background: url("//www.house123.com.tw/static/skins/billiejean/images/logo_nobeta2.png") no-repeat scroll 0 13px rgba(0, 0, 0, 0);
  display: block;
  height: 70px;
  margin-top: 2px;
  text-indent: -9999px;
  width: 255px;
}

// /* line 75, ../../frontend/scss/_base.scss */
// .col-navbar {
//   padding-right: 0;
// }

// /* line 79, ../../frontend/scss/_base.scss */
// .navbar {
//   z-index: 20;
// }
// /* line 82, ../../frontend/scss/_base.scss */
// .navbar nav {
//   padding: 48px 0 25px 0;
// }
// /* line 85, ../../frontend/scss/_base.scss */
// .navbar nav > ul {
//   text-align: right;
//   vertical-align: bottom;
//   float: right;
//   list-style-type: none;
// }
// /* line 94, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list > li {
//   /* display: inline;*/
//   float: left;
//   font-size: 19px;
//   margin: 0 5px;
//   line-height: 1;
//   position: relative;
// }
// /* line 102, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list > li > a {
//   padding: 7px 5px 2px;
//   margin: 0 5px;
//   /* border: 1px solid transparent;*/
//   color: #424242;
//   border-bottom: 4px solid transparent;
//   -webkit-transition: color 100ms ease-out, border 100ms ease-out;
//   -moz-transition: color 100ms ease-out, border 100ms ease-out;
//   -o-transition: color 100ms ease-out, border 100ms ease-out;
//   -ms-transition: color 100ms ease-out, border 100ms ease-out;
//   transition: color 100ms ease-out, border 100ms ease-out;
// }
// /* line 117, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list > li.current > a {
//   color: #23A8E2;
// }
// /* line 123, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list > li:hover > a {
//   color: #23A8E2;
// }
// /* line 127, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list > li:hover > .nav_list_second {
//   visibility: visible;
// }
// /* line 135, ../../frontend/scss/_base.scss */
// .navbar nav li:last-child {
//   margin-right: 0;
// }
// /* line 140, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second {
//   position: absolute;
//   text-align: left;
//   top: 22px;
//   left: -2px;
//   width: 150px;
//   visibility: hidden;
// }
// /* line 148, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second ul {
//   list-style-type: none;
// }
// /* line 152, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second .triangle {
//   border-left: 7px solid transparent;
//   border-right: 7px solid transparent;
//   border-bottom: 7px solid rgba(183, 227, 246, 0.95);
//   border-bottom: 7px solid #b7e3f6;
//   width: 0;
//   height: 0;
//   left: 15px;
//   top: 3px;
//   position: absolute;
// }
// /* line 164, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second li {
//   line-height: 24px;
// }
// /* line 168, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second li:first-child a {
//   margin-top: 10px;
// }
// /* line 174, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second a {
//   font-size: 16px;
//   color: #333;
//   background-color: rgba(183, 227, 246, 0.95);
//   background-color: #b7e3f6;
//   padding: 8px 12px;
//   display: block;
// }
// /* line 182, ../../frontend/scss/_base.scss */
// .navbar nav .nav_list_second a:hover {
//   color: #FFF;
//   background-color: rgba(35, 168, 226, 0.95);
//   background-color: #23a8e2;
// }

/* line 192, ../../frontend/scss/_base.scss */
// .footer {
//   padding-top: 20px;
//   background-color: #FFF;
//   width: 100%;
//   height: 588px;
// }
// /* line 198, ../../frontend/scss/_base.scss */
// .footer #footer-fb {
//   margin-bottom: 30px;
//   height: 204px;
//   overflow: hidden;
// }
// /* line 204, ../../frontend/scss/_base.scss */
// .footer .unit {
//   float: left;
// }
// /* line 208, ../../frontend/scss/_base.scss */
// .footer .size2of5 {
//   width: 40%;
// }
// /* line 212, ../../frontend/scss/_base.scss */
// .footer .size1of5 {
//   width: 20%;
// }
// /* line 216, ../../frontend/scss/_base.scss */
// .footer .footer_first {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/footer_bg_line.png) repeat-x 0px 62px;
// }
// /* line 219, ../../frontend/scss/_base.scss */
// .footer .footer_first .container {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/footer_bg_building.png) no-repeat 95% 1px;
//   height: 67px;
// }
// /* line 225, ../../frontend/scss/_base.scss */
// .footer .icon {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/footer_sprite.png) no-repeat;
//   width: 16px;
//   height: 16px;
//   display: inline-block;
//   vertical-align: text-top;
//   margin: 1px 9px 0 0;
// }
// /* line 236, ../../frontend/scss/_base.scss */
// .footer .icon.email {
//   background-position: -16px 0;
// }
// /* line 240, ../../frontend/scss/_base.scss */
// .footer .icon.time {
//   background-position: -32px 0;
// }
// /* line 244, ../../frontend/scss/_base.scss */
// .footer .icon.location {
//   background-position: -48px 0;
// }
// /* line 248, ../../frontend/scss/_base.scss */
// .footer .icon.fax {
//   background-position: -128px 0;
// }
// /* line 252, ../../frontend/scss/_base.scss */
// .footer .icon.facebook-fan {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/house123fan.png) no-repeat;
//   width: 64px;
//   height: 64px;
//   float: left;
// }
// /* line 260, ../../frontend/scss/_base.scss */
// .footer .sep {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/footer_line.png) repeat-x;
//   height: 2px;
//   width: 100%;
// }
// /* line 266, ../../frontend/scss/_base.scss */
// .footer .footer_second {
//   background: url(//www.house123.com.tw/static/skins/billiejean/images/footer_bg2.png) repeat;
// }
// /* line 269, ../../frontend/scss/_base.scss */
// .footer .footer_second .container {
//   padding: 15px 0;
// }
// /* line 272, ../../frontend/scss/_base.scss */
// .footer .footer_second .container h3 {
//   font-size: 17px;
//   text-shadow: 0 1px #FFF;
// }
// /* line 278, ../../frontend/scss/_base.scss */
// .footer .footer_second .services {
//   list-style-type: none;
//   padding: 15px 0 0;
// }
// /* line 282, ../../frontend/scss/_base.scss */
// .footer .footer_second .services li {
//   font-size: 13px;
//   margin-bottom: 8px;
// }
// /* line 287, ../../frontend/scss/_base.scss */
// .footer .footer_second .services i {
//   float: left;
// }
// /* line 292, ../../frontend/scss/_base.scss */
// .footer .footer_second .item {
//   margin-left: 25px;
// }
// /* line 296, ../../frontend/scss/_base.scss */
// .footer .footer_second .about {
//   margin-top: 12px;
//   line-height: 1.75;
//   width: 85%;
//   font-size: 14px;
// }
// /* line 305, ../../frontend/scss/_base.scss */
// .footer .footer_third {
//   height: 34px;
//   color: #FFF;
//   background-color: #5fb7d3;
// }
// /* line 310, ../../frontend/scss/_base.scss */
// .footer .footer_third .container {
//   padding-top: 10px;
//   height: 21px;
//   font-size: 13px;
// }
// /* line 316, ../../frontend/scss/_base.scss */
// .footer .footer_third .right {
//   text-align: right;
// }
// /* line 320, ../../frontend/scss/_base.scss */
// .footer .footer_third a {
//   color: #FFF;
// }
// /* line 323, ../../frontend/scss/_base.scss */
// .footer .footer_third a:hover {
//   color: #EFEFEF;
// }

/*
.footer {
    margin-top: 20px;
    font-size: 12px;

    .terms {
        display: inline;
        margin-left: 0px;

        li {
            display: inline;
            margin-left: 10px;
        }
    }
}

.footer-left {
    height: 190px;
}

.footer-right {
    padding-top: 10px;
    padding-bottom: 12px;
    @media (min-width: $screen-md-min) {
        padding-top: 144px;
    }

    @media (max-width: 842px) {
        padding-right: 50px;
    }
}
*/
/* line 364, ../../frontend/scss/_base.scss */
.terms {
  padding-top: 5px;
  margin-bottom: 0;
}

/* line 369, ../../frontend/scss/_base.scss */
.static-page {
  font-size: 16px;
  line-height: 1.4;
}
/* line 373, ../../frontend/scss/_base.scss */
.static-page h1 {
  font-weight: normal;
}
/* line 377, ../../frontend/scss/_base.scss */
.static-page h3 {
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: normal;
}
/* line 383, ../../frontend/scss/_base.scss */
.static-page p {
  margin-bottom: 20px;
  color: #555;
}
/* line 389, ../../frontend/scss/_base.scss */
.static-page ol li {
  margin: 5px 0;
}
/* line 393, ../../frontend/scss/_base.scss */
.static-page ol ol {
  list-style-type: lower-alpha;
}
/* line 398, ../../frontend/scss/_base.scss */
.static-page li {
  line-height: 1.4;
}

/* line 2, ../../frontend/scss/_modal.scss */
.modal-body p {
  margin-bottom: 20px;
}

/* line 7, ../../frontend/scss/_modal.scss */
.padding-up {
  padding-top: 20px;
}

/* line 11, ../../frontend/scss/_modal.scss */
#main-modal {
  position: relative;
}
/* line 14, ../../frontend/scss/_modal.scss */
#main-modal .modal-body {
  max-height: none;
}

/* line 19, ../../frontend/scss/_modal.scss */
.slide-enter-setup, .slide-leavel-setup {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

/* line 25, ../../frontend/scss/_modal.scss */
.intro-img {
  display: block;
  margin: 13px 0;
}

/* line 32, ../../frontend/scss/_modal.scss */
.introduction .modal-body {
  height: 370px;
}
/* line 37, ../../frontend/scss/_modal.scss */
.introduction .modal-footer label {
  margin-top: 5px;
}
/* line 42, ../../frontend/scss/_modal.scss */
.introduction h3 {
  margin: 0;
}
/* line 46, ../../frontend/scss/_modal.scss */
.introduction .title {
  top: 22px;
  left: 120px;
  position: absolute;
}

/* line 53, ../../frontend/scss/_modal.scss */
#signup-modal .center {
  margin: 0 auto;
}

/* line 58, ../../frontend/scss/_modal.scss */
.modal form {
  margin-bottom: 0;
}

/* line 1, ../../frontend/scss/_iewarning.scss */
.ie-warning {
  width: 100%;
  color: #DDD;
  background-color: #5FB7D3;
  height: 60px;
  position: fixed;
  top: -57px;
  z-index: 5000;
}
/* line 11, ../../frontend/scss/_iewarning.scss */
.ie-warning .container {
  position: relative;
  padding-top: 3px;
}
/* line 16, ../../frontend/scss/_iewarning.scss */
.ie-warning .current {
  font-size: 12px;
}
/* line 20, ../../frontend/scss/_iewarning.scss */
.ie-warning .title {
  font-size: 23px;
  font-weight: 300;
}
/* line 25, ../../frontend/scss/_iewarning.scss */
.ie-warning .close {
  position: absolute;
  right: 0;
  top: 3px;
  color: #FFF;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  text-shadow: 0px 1px 0px black;
  z-index: 5;
}
/* line 35, ../../frontend/scss/_iewarning.scss */
.ie-warning .title.red {
  color: red;
}
/* line 39, ../../frontend/scss/_iewarning.scss */
.ie-warning .content {
  padding-top: 6px;
  text-align: right;
}
/* line 44, ../../frontend/scss/_iewarning.scss */
.ie-warning a {
  color: #EFEFEF;
  text-decoration: underline;
}
/* line 48, ../../frontend/scss/_iewarning.scss */
.ie-warning a:hover {
  color: #FFF;
}

/* line 54, ../../frontend/scss/_iewarning.scss */
// .navbar {
//   top: 2px;
//   height: 84px;
// }

/* line 2, ../../frontend/scss/_welcome.scss */
.welcome h3 {
  margin-top: 0;
}
/* line 6, ../../frontend/scss/_welcome.scss */
.welcome p {
  margin-bottom: 16px;
}
/* line 9, ../../frontend/scss/_welcome.scss */
.welcome p:last-of-type {
  margin-bottom: 0;
}

/* line 15, ../../frontend/scss/_welcome.scss */
.welcome-btn-block {
  position: fixed;
  line-height: 1.1;
  font-size: 13px;
  bottom: 58px;
  right: 15px;
  width: 30px;
}

/* line 24, ../../frontend/scss/_welcome.scss */
.survey-block {
  z-index: 3000;
  position: fixed;
  bottom: 63px;
  right: -214px;
  overflow: hidden;
  -webkit-transition-property: right;
  -moz-transition-property: right;
  -o-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
/* line 34, ../../frontend/scss/_welcome.scss */
.survey-block.opened {
  right: -1px;
}
/* line 39, ../../frontend/scss/_welcome.scss */
.survey-block:hover .close-btn {
  top: 0;
}
/* line 45, ../../frontend/scss/_welcome.scss */
.survey-block.touch .close-btn {
  top: 0 !important;
}
/* line 50, ../../frontend/scss/_welcome.scss */
.survey-block .close-btn {
  position: absolute;
  top: -17px;
  right: 0px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #DDD;
  display: block;
  padding: 3px;
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  transition-property: top;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
/* line 63, ../../frontend/scss/_welcome.scss */
.survey-block .close-btn:hover {
  color: #EFEFEF;
}

/* line 69, ../../frontend/scss/_welcome.scss */
.survey-block2 {
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  margin: 20px 0 40px;
  padding: 20px 0;
}
/* line 75, ../../frontend/scss/_welcome.scss */
.property-item .survey-block2 {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
  border-bottom-color: #EFEFEF;
}

/* line 1, ../../frontend/scss/_tinymce.scss */
#tinymce {
  padding: 10px;
}

/* line 2, ../../frontend/scss/_transrec.scss */
.transrec-page #fb-like-line {
  height: 60px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  /* line 2, ../../frontend/scss/_transrec.scss */
  .transrec-page #fb-like-line {
    height: 23px;
  }
}
/* line 10, ../../frontend/scss/_transrec.scss */
.transrec-page #fb-like-line iframe {
  height: 25px;
  overflow: hidden;
}
/* line 16, ../../frontend/scss/_transrec.scss */
.transrec-page .fb-like {
  height: 25px;
}
@media (max-width: 767px) {
  /* line 16, ../../frontend/scss/_transrec.scss */
  .transrec-page .fb-like {
    display: none;
  }
}
/* line 23, ../../frontend/scss/_transrec.scss */
.transrec-page .mask {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
}
/* line 27, ../../frontend/scss/_transrec.scss */
.transrec-page .pagination {
  padding-bottom: 15px;
}
/* line 30, ../../frontend/scss/_transrec.scss */
.transrec-page .init-help-text {
  color: #888;
}

/* line 35, ../../frontend/scss/_transrec.scss */
.control-block {
  margin-bottom: 12px;
}
/* line 38, ../../frontend/scss/_transrec.scss */
.control-block .tag {
  padding-bottom: 4px;
  font-size: 17px;
}
/* line 43, ../../frontend/scss/_transrec.scss */
.control-block .radio-inline {
  margin-right: 10px;
}
/* line 47, ../../frontend/scss/_transrec.scss */
.control-block .radio-inline + .radio-inline {
  margin-left: 0;
}
/* line 51, ../../frontend/scss/_transrec.scss */
.control-block .road-error-block {
  color: red;
  padding-top: 3px;
  font-size: 13px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
/* line 59, ../../frontend/scss/_transrec.scss */
.control-block .road-error-block.active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* line 65, ../../frontend/scss/_transrec.scss */
.list-control {
  margin-left: 0;
  padding-left: 0;
}
/* line 69, ../../frontend/scss/_transrec.scss */
.list-control li {
  display: inline-block;
  margin-bottom: 3px;
}
/* line 73, ../../frontend/scss/_transrec.scss */
.list-control li > a {
  padding: 2px 5px;
}
/* line 78, ../../frontend/scss/_transrec.scss */
.list-control .selected {
  background-color: #005580;
  color: #EEE;
  border-radius: 3px;
}

/* line 85, ../../frontend/scss/_transrec.scss */
.selected-line {
  width: 100%;
  height: 4px;
  margin-bottom: 5px;
}

/* line 91, ../../frontend/scss/_transrec.scss */
.list-lines {
  margin-top: 3px;
  margin-bottom: 4px;
  font-size: 14px;
}
/* line 96, ../../frontend/scss/_transrec.scss */
.list-lines a {
  font-weight: bold;
}
/* line 101, ../../frontend/scss/_transrec.scss */
.list-lines [class^="line-"]:not(.selected):hover,
.list-lines [class*=" line-"]:not(.selected):hover {
  color: #555;
}

/* line 107, ../../frontend/scss/_transrec.scss */
.list-stations .selected:hover {
  color: #FFF !important;
}
/* line 112, ../../frontend/scss/_transrec.scss */
.list-stations [class^="line-"],
.list-stations [class*=" line-"] {
  color: #555;
}
/* line 115, ../../frontend/scss/_transrec.scss */
.list-stations [class^="line-"]:hover,
.list-stations [class*=" line-"]:hover {
  color: #888;
}

/* line 128, ../../frontend/scss/_transrec.scss */
.list-lines .line-brown {
  color: #c38d33;
}
/* line 132, ../../frontend/scss/_transrec.scss */
.line-brown.selected {
  background-color: #c38d33;
  color: #FFF;
}

/* line 128, ../../frontend/scss/_transrec.scss */
.list-lines .line-red {
  color: #e3002d;
}
/* line 132, ../../frontend/scss/_transrec.scss */
.line-red.selected {
  background-color: #e3002d;
  color: #FFF;
}

/* line 128, ../../frontend/scss/_transrec.scss */
.list-lines .line-blue {
  color: #0070bc;
}
/* line 132, ../../frontend/scss/_transrec.scss */
.line-blue.selected {
  background-color: #0070bc;
  color: #FFF;
}

/* line 128, ../../frontend/scss/_transrec.scss */
.list-lines .line-orange {
  color: #f9b41b;
}
/* line 132, ../../frontend/scss/_transrec.scss */
.line-orange.selected {
  background-color: #f9b41b;
  color: #FFF;
}

/* line 128, ../../frontend/scss/_transrec.scss */
.list-lines .line-green {
  color: #028758;
}
/* line 132, ../../frontend/scss/_transrec.scss */
.line-green.selected {
  background-color: #028758;
  color: #FFF;
}

/* line 159, ../../frontend/scss/_transrec.scss */
.map-help {
  padding-top: 10px;
  color: #333;
  font-size: 13px;
}

/* line 165, ../../frontend/scss/_transrec.scss */
.staytop {
  display: none;
  position: fixed;
  top: 43px;
  padding-top: 3px;
  width: 100%;
  left: 0;
  background: white;
  background: transparent\9;
  background: rgba(255, 255, 255, 0.9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#e5FFFFFF,endColorstr=#e5FFFFFF);
  zoom: 1;
}
/* line 181, ../../frontend/scss/_transrec.scss */
.staytop:nth-child(n) {
  filter: none;
}
/* line 185, ../../frontend/scss/_transrec.scss */
.staytop table {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 191, ../../frontend/scss/_transrec.scss */
  .report th {
    font-size: 13px;
  }
}
/* line 199, ../../frontend/scss/_transrec.scss */
.report tr:hover td, .report tr:hover .age, .report tr:hover .sample-no, .report tr:hover .type {
  color: #0088CC;
}
/* line 205, ../../frontend/scss/_transrec.scss */
.report td {
  vertical-align: middle;
  cursor: pointer;
  color: #777;
  font-family: "Open Sans", "Heiti TC", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
  height: 24px;
}
/* line 213, ../../frontend/scss/_transrec.scss */
.report td > span, .report td > div {
  line-height: 20px;
}
/* line 217, ../../frontend/scss/_transrec.scss */
.report .type {
  font-size: 20px;
}
/* line 221, ../../frontend/scss/_transrec.scss */
.report .age {
  font-size: 13px;
  color: #999;
}
/* line 226, ../../frontend/scss/_transrec.scss */
.report .price {
  font-size: 25px;
}
/* line 230, ../../frontend/scss/_transrec.scss */
.report .sample-no {
  font-size: 12px;
  color: #999;
}
/* line 236, ../../frontend/scss/_transrec.scss */
.report.type-selected td, .report.type-selected .type {
  color: #999;
}
/* line 240, ../../frontend/scss/_transrec.scss */
.report.type-selected tr:not(.active):hover .type {
  color: #0088CC;
}
/* line 244, ../../frontend/scss/_transrec.scss */
.report.type-selected .age {
  color: #CCC;
}
/* line 249, ../../frontend/scss/_transrec.scss */
.report .width-type {
  width: 149px;
}
/* line 253, ../../frontend/scss/_transrec.scss */
.report .width-counts {
  width: 86px;
}
/* line 258, ../../frontend/scss/_transrec.scss */
.report .active td {
  color: #333;
}
/* line 262, ../../frontend/scss/_transrec.scss */
.report .active .sample-no {
  color: #666;
}
/* line 266, ../../frontend/scss/_transrec.scss */
.report .active .type {
  color: #000;
}
/* line 270, ../../frontend/scss/_transrec.scss */
.report .active .age {
  color: #333;
}

/* line 277, ../../frontend/scss/_transrec.scss */
.records-wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  /* line 277, ../../frontend/scss/_transrec.scss */
  .records-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

/* line 285, ../../frontend/scss/_transrec.scss */
.records {
  min-width: 720px;
}
/* line 288, ../../frontend/scss/_transrec.scss */
.records .floor-total {
  font-size: 13px;
  color: #666;
}
/* line 293, ../../frontend/scss/_transrec.scss */
.records .park-type {
  font-size: 14px;
  width: 30px;
}
/* line 299, ../../frontend/scss/_transrec.scss */
.records td.padding {
  padding-right: 25px;
}
/* line 303, ../../frontend/scss/_transrec.scss */
.records td.padding-1cc {
  padding-right: 5px;
}
/* line 307, ../../frontend/scss/_transrec.scss */
.records td.padding-price-unit {
  padding-right: 0px;
}
/* line 312, ../../frontend/scss/_transrec.scss */
.records th {
  min-width: 48px;
}
/* line 315, ../../frontend/scss/_transrec.scss */
.records th.padding-price-unit {
  padding-right: 17px;
}
/* line 320, ../../frontend/scss/_transrec.scss */
.records .td-price .price {
  color: #ed2914;
  font-weight: 700;
}
/* line 324, ../../frontend/scss/_transrec.scss */
.records .td-price .price .price-point {
  font-size: 80%;
}
/* line 329, ../../frontend/scss/_transrec.scss */
.records .glyphicon {
  font-size: 10px;
  width: 10px;
  height: 10px;
}
/* line 335, ../../frontend/scss/_transrec.scss */
.records .width-dealzone {
  width: 63px;
}
/* line 339, ../../frontend/scss/_transrec.scss */
.records .width-type {
  width: 63px;
}
/* line 343, ../../frontend/scss/_transrec.scss */
.records .width-price {
  width: 107px;
}
/* line 347, ../../frontend/scss/_transrec.scss */
.records .width-object-area {
  width: 113px;
}
/* line 351, ../../frontend/scss/_transrec.scss */
.records .width-price-unit {
  width: 101px;
}
/* line 355, ../../frontend/scss/_transrec.scss */
.records .width-land-area {
  width: 86px;
}
/* line 359, ../../frontend/scss/_transrec.scss */
.records .width-age {
  width: 66px;
}
/* line 363, ../../frontend/scss/_transrec.scss */
.records .width-floor {
  width: 63px;
}
/* line 367, ../../frontend/scss/_transrec.scss */
.records .width-parking {
  width: 60px;
}
/* line 371, ../../frontend/scss/_transrec.scss */
.records .dealzone-year {
  font-size: 13px;
  color: #666;
}
/* line 376, ../../frontend/scss/_transrec.scss */
.records .dealzone-month {
  color: #444;
}
/* line 379, ../../frontend/scss/_transrec.scss */
.records .dealzone-month .unit {
  font-size: 12px;
}
/* line 384, ../../frontend/scss/_transrec.scss */
.records .unit {
  font-size: 12px;
  color: #888;
  white-space: nowrap;
}
/* line 391, ../../frontend/scss/_transrec.scss */
.records .no-results td {
  height: 100px;
}

/* line 397, ../../frontend/scss/_transrec.scss */
.data-block {
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

/* line 403, ../../frontend/scss/_transrec.scss */
.loading {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
}

/* line 408, ../../frontend/scss/_transrec.scss */
.fixed-header .staytop {
  display: block;
}
@media (max-width: 767px) {
  /* line 408, ../../frontend/scss/_transrec.scss */
  .fixed-header .staytop {
    display: none;
  }
}
/* line 415, ../../frontend/scss/_transrec.scss */
.fixed-header #table-records thead {
  visibility: hidden;
}

/* line 420, ../../frontend/scss/_transrec.scss */
#condition-display {
  width: 100%;
  left: 0;
  height: 40px;
  border-bottom: 1px solid #999;
  margin-bottom: 10px;
}
/* line 428, ../../frontend/scss/_transrec.scss */
#condition-display .container {
  padding-left: 0;
  padding-right: 0;
}
/* line 432, ../../frontend/scss/_transrec.scss */
.fixed-condition #condition-display .container {
  padding-left: 15px;
  padding-right: 15px;
}
/* line 438, ../../frontend/scss/_transrec.scss */
.fixed-condition #condition-display {
  background-color: white;
  position: fixed;
  top: 2px;
  z-index: 1031;
}

/* line 447, ../../frontend/scss/_transrec.scss */
.fixed-condition .condition-block {
  margin-top: 6px;
}
/* line 451, ../../frontend/scss/_transrec.scss */
.fixed-condition #condition-display-placeholder {
  height: 40px;
}

/* line 456, ../../frontend/scss/_transrec.scss */
.condition-label {
  display: inline-block;
  font-size: 15px;
  background-color: #DDD;
  border-bottom: 1px solid #DDD;
  border-radius: 2px;
  text-align: center;
  padding: 3px 6px;
  margin-right: 6px;
}

/* line 467, ../../frontend/scss/_transrec.scss */
.conditions {
  display: inline-block;
}

/* line 471, ../../frontend/scss/_transrec.scss */
.condition {
  display: inline-block;
  margin-right: 6px;
  border-radius: 2px;
  border: 1px solid #005580;
  padding: 3px 6px;
  font-size: 15px;
}
/* line 479, ../../frontend/scss/_transrec.scss */
.condition.resettable {
  cursor: pointer;
}
/* line 482, ../../frontend/scss/_transrec.scss */
.condition.resettable:hover {
  background-color: #C2E5FF;
}

/* line 488, ../../frontend/scss/_transrec.scss */
.map {
  width: 100%;
  height: 280px;
}
/* line 492, ../../frontend/scss/_transrec.scss */
.map img {
  max-width: none;
}

/* line 497, ../../frontend/scss/_transrec.scss */
.nav li a {
  cursor: pointer;
}

/* line 502, ../../frontend/scss/_transrec.scss */
.news-block .label {
  float: left;
  margin-top: 2px;
  margin-right: 3px;
  line-height: 1;
}
/* line 509, ../../frontend/scss/_transrec.scss */
.news-block .news-item {
  float: left;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  opacity: 0;
}
/* line 515, ../../frontend/scss/_transrec.scss */
.news-block .news-item.active {
  opacity: 1;
}

/* line 522, ../../frontend/scss/_transrec.scss */
.transrec-detail .modal-header {
  padding-bottom: 10px;
}
/* line 523, ../../frontend/scss/_transrec.scss */
.transrec-detail .modal-header h4 {
  margin-top: 0;
  margin-bottom: 0;
}
/* line 531, ../../frontend/scss/_transrec.scss */
.transrec-detail .modal-body {
  padding-top: 10px;
  padding-bottom: 5px;
}
/* line 535, ../../frontend/scss/_transrec.scss */
.transrec-detail .modal-body table {
  margin-bottom: 0;
}
/* line 540, ../../frontend/scss/_transrec.scss */
.transrec-detail .map {
  margin-bottom: 5px;
}
/* line 544, ../../frontend/scss/_transrec.scss */
.transrec-detail .unit {
  font-size: 12px;
  color: #888;
  white-space: nowrap;
}
/* line 550, ../../frontend/scss/_transrec.scss */
.transrec-detail .price {
  color: #ed2914;
  font-weight: 700;
}
/* line 554, ../../frontend/scss/_transrec.scss */
.transrec-detail .price .price-point {
  font-size: 80%;
}
/* line 559, ../../frontend/scss/_transrec.scss */
.transrec-detail .grouped {
  padding-right: 8px;
}
/* line 563, ../../frontend/scss/_transrec.scss */
.transrec-detail .modal-footer {
  margin-top: 0;
}

/* line 2, ../../frontend/scss/_xchange.scss */
.xchange h1 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 2px;
  line-height: 1;
  margin-top: 5px;
}
/* line 10, ../../frontend/scss/_xchange.scss */
.xchange .description {
  font-size: 18px;
}
/* line 14, ../../frontend/scss/_xchange.scss */
.xchange .contact .name {
  font-size: 17px;
}
/* line 18, ../../frontend/scss/_xchange.scss */
.xchange .adattr {
  height: 37px;
  background: #EEE;
  padding: 10px 0;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 3px rgba(76, 76, 76, 0.2) inset;
  margin-bottom: 20px;
}
/* line 26, ../../frontend/scss/_xchange.scss */
.xchange .adattr .unit {
  font-size: 12px;
}
/* line 30, ../../frontend/scss/_xchange.scss */
.xchange .adattr .item {
  float: left;
  padding: 0 15px;
  border-left: 1px solid #CCC;
}
/* line 35, ../../frontend/scss/_xchange.scss */
.xchange .adattr .item:first-child {
  border-left: none;
}
/* line 40, ../../frontend/scss/_xchange.scss */
.xchange .adattr .tag {
  font-size: 12px;
  color: #888;
  text-shadow: 0px 1px 0px #FFF;
}
/* line 46, ../../frontend/scss/_xchange.scss */
.xchange .adattr .value {
  font-size: 18px;
  color: #555;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  height: 18px;
}
/* line 55, ../../frontend/scss/_xchange.scss */
.xchange .date-tag {
  font-family: "Open Sans", sans-serif;
  text-align: right;
  color: #777;
  font-weight: 300;
  font-size: 12px;
}
/* line 64, ../../frontend/scss/_xchange.scss */
.xchange .head {
  position: relative;
  width: 100%;
}
/* line 69, ../../frontend/scss/_xchange.scss */
.xchange .meta-attr {
  position: absolute;
  bottom: 0;
  text-align: right;
  right: 0;
}
/* line 76, ../../frontend/scss/_xchange.scss */
.xchange .ad {
  border-top: 1px solid #CCC;
  padding: 10px 0;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
/* line 83, ../../frontend/scss/_xchange.scss */
.xchange .ad h2 {
  font-size: 20px;
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
  color: #444;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
/* line 92, ../../frontend/scss/_xchange.scss */
.xchange .ad h2 .label {
  margin-left: 5px;
  font-size: 9px;
  font-weight: 400;
}
/* line 99, ../../frontend/scss/_xchange.scss */
.xchange .ad a {
  display: block;
  color: #666;
  transition: all 0.3s;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
/* line 106, ../../frontend/scss/_xchange.scss */
.xchange .ad a:hover {
  text-decoration: none;
  color: #444;
}
/* line 110, ../../frontend/scss/_xchange.scss */
.xchange .ad a:hover h2 {
  color: #222;
}
/* line 116, ../../frontend/scss/_xchange.scss */
.xchange .ad:hover {
  background-color: #EFEFEF;
}
/* line 120, ../../frontend/scss/_xchange.scss */
.xchange .ad .cover-thumbnail {
  padding-left: 10px;
}
/* line 126, ../../frontend/scss/_xchange.scss */
.xchange.post .control-label {
  width: 120px;
}
/* line 130, ../../frontend/scss/_xchange.scss */
.xchange.post .controls {
  margin-left: 140px;
}
/* line 134, ../../frontend/scss/_xchange.scss */
.xchange.post .chzn-container {
  padding-top: 3px;
}
/* line 138, ../../frontend/scss/_xchange.scss */
.xchange.post .req {
  color: red;
  font-size: 19px;
  line-height: 15px;
}
/* line 144, ../../frontend/scss/_xchange.scss */
.xchange.post .em {
  color: red;
}
/* line 148, ../../frontend/scss/_xchange.scss */
.xchange.post .unit {
  padding-right: 12px;
}
/* line 152, ../../frontend/scss/_xchange.scss */
.xchange.post .thumbnail {
  padding: 0;
  position: relative;
  overflow: hidden;
  min-height: 30px;
}
/* line 159, ../../frontend/scss/_xchange.scss */
.xchange.post .form-actions {
  padding-left: 140px;
}
/* line 164, ../../frontend/scss/_xchange.scss */
.xchange .status-pr, .xchange .status-in {
  color: #999;
}
/* line 168, ../../frontend/scss/_xchange.scss */
.xchange .location {
  padding-bottom: 3px;
  font-size: 16px;
  color: #676767;
}

/* line 175, ../../frontend/scss/_xchange.scss */
.errorlist {
  list-style-type: none;
  margin-left: 0;
  color: red;
}

/* line 182, ../../frontend/scss/_xchange.scss */
.thumbnail.uploading {
  height: 199px;
}
/* line 187, ../../frontend/scss/_xchange.scss */
.thumbnail:hover .thumbnail-status {
  bottom: 0;
}

/* line 193, ../../frontend/scss/_xchange.scss */
.thumbnail-image {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* line 198, ../../frontend/scss/_xchange.scss */
.uploading .thumbnail-image {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

/* line 203, ../../frontend/scss/_xchange.scss */
.thumbnail-creating {
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  padding-top: 88px;
}

/* line 210, ../../frontend/scss/_xchange.scss */
.thumbnail-status {
  position: absolute;
  bottom: -30px;
  height: 30px;
  background-color: rgba(20, 20, 20, 0.8);
  width: 100%;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  color: #EFEFEF;
}
/* line 220, ../../frontend/scss/_xchange.scss */
.thumbnail-status ul {
  padding: 4px 8px;
}
/* line 223, ../../frontend/scss/_xchange.scss */
.thumbnail-status ul li {
  float: left;
  padding-right: 10px;
}
/* line 229, ../../frontend/scss/_xchange.scss */
.thumbnail-status a {
  color: #DDD;
  cursor: pointer;
}
/* line 233, ../../frontend/scss/_xchange.scss */
.thumbnail-status a:hover, .thumbnail-status a:active, .thumbnail-status a:visited, .thumbnail-status a:focus {
  color: #FFF;
  text-decoration: none;
}

/* line 240, ../../frontend/scss/_xchange.scss */
.thumbnail-progress {
  position: absolute;
  bottom: 0px;
  height: 5px;
  background-color: rgba(20, 20, 20, 0.5);
  color: #EFEFEF;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

/* line 250, ../../frontend/scss/_xchange.scss */
.thumbnail-tag {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: rgba(20, 20, 20, 0.8);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  color: #EFEFEF;
  padding: 3px 8px;
  border-radius: 2px;
}

/* line 263, ../../frontend/scss/_xchange.scss */
[data-cover] .thumbnail-tag {
  top: 0px;
}
/* line 267, ../../frontend/scss/_xchange.scss */
[data-cover] .cover-item {
  display: none;
}

/* line 273, ../../frontend/scss/_xchange.scss */
.preview .main {
  // background: url("../images/construction_bg.png") repeat;
  margin-left: 0 !important;
  padding: 20px;
}
/* line 281, ../../frontend/scss/_xchange.scss */
.preview .side {
  width: 200px;
}
/* line 285, ../../frontend/scss/_xchange.scss */
.preview .button {
  border: 1px solid #000;
  padding: 10px;
  line-height: 40px;
}
/* line 290, ../../frontend/scss/_xchange.scss */
.preview .button:hover {
  text-decoration: none;
}

/* line 296, ../../frontend/scss/_xchange.scss */
.upload-buttons {
  margin-bottom: 15px;
}

/* line 300, ../../frontend/scss/_xchange.scss */
input.input-mini {
  width: 24px;
}

/* line 2, ../../frontend/scss/search/_filter.scss */
.top-selectors .block {
  margin: 5px 0 10px;
  padding: 5px 12px 5px 0;
  border: 1px solid transparent;
  float: left;
}

/* line 10, ../../frontend/scss/search/_filter.scss */
.dialog-section {
  position: relative;
}
/* line 13, ../../frontend/scss/search/_filter.scss */
.dialog-section .clear {
  position: absolute;
  right: 0;
  top: 0;
}

/* line 20, ../../frontend/scss/search/_filter.scss */
#dialog-district {
  border-top: 1px solid #BCBCBC;
  padding-top: 10px;
  padding-bottom: 15px;
}
/* line 25, ../../frontend/scss/search/_filter.scss */
#dialog-district .clear {
  top: 15px;
}
/* line 30, ../../frontend/scss/search/_filter.scss */
#dialog-district ul > li {
  width: 121px;
}

/* line 36, ../../frontend/scss/search/_filter.scss */
.region-selector {
  cursor: pointer;
  position: relative;
  z-index: 1000;
}
/* line 42, ../../frontend/scss/search/_filter.scss */
.region-selector .region-area h5 {
  margin: 0 0 0 2px;
  width: 33px;
  text-align: right;
  float: left;
  padding-top: 4px;
  font-weight: 700;
}
/* line 51, ../../frontend/scss/search/_filter.scss */
.region-selector .region-area .cities {
  float: left;
  width: 490px;
  padding-left: 10px;
}
/* line 58, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog {
  position: absolute;
  left: 0;
  top: 50px;
  width: 555px;
  border: 1px solid #999;
  background-color: rgba(250, 250, 250, 0.95);
  background-color: #fafafa;
  padding: 5px 12px;
  display: none;
}
/* line 69, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog.show {
  display: block;
}
/* line 73, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog li {
  float: left;
  width: 110px;
  margin-right: 10px;
  border: 1px solid transparent;
  padding: 1px 0 1px 0;
  height: 28px;
  list-style-type: none;
}
/* line 82, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog li a {
  color: #676767;
}
/* line 85, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog li a:hover {
  color: #343434;
}
/* line 91, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog .key {
  font-size: 15px;
}
/* line 95, ../../frontend/scss/search/_filter.scss */
.region-selector .dialog .count {
  font-size: 10px;
  color: #777;
}
/* line 103, ../../frontend/scss/search/_filter.scss */
.region-selector li.selected .count {
  font-weight: normal;
}
/* line 107, ../../frontend/scss/search/_filter.scss */
.region-selector li.selected a {
  color: #005800;
  font-weight: 700;
}
/* line 114, ../../frontend/scss/search/_filter.scss */
.region-selector input[type="checkbox"] {
  margin-top: 0;
}

/* line 119, ../../frontend/scss/search/_filter.scss */
.search-hfilter {
  margin-bottom: 0;
  float: left;
  padding-right: 10px;
}
/* line 124, ../../frontend/scss/search/_filter.scss */
.search-hfilter .default {
  color: #999;
}
/* line 128, ../../frontend/scss/search/_filter.scss */
.search-hfilter h4 {
  font-size: 11px;
  margin: 0;
  color: #666;
}
/* line 134, ../../frontend/scss/search/_filter.scss */
.search-hfilter ul {
  margin-bottom: 0;
}
/* line 138, ../../frontend/scss/search/_filter.scss */
.search-hfilter li {
  display: inline;
  padding-right: 7px;
  color: #005580;
  font-weight: 700;
}
/* line 144, ../../frontend/scss/search/_filter.scss */
.search-hfilter li.last-caret {
  margin-left: -7px;
  padding-right: 0;
}
/* line 150, ../../frontend/scss/search/_filter.scss */
.search-hfilter .item {
  color: #999;
}
/* line 153, ../../frontend/scss/search/_filter.scss */
.search-hfilter .item:hover {
  color: #666;
}
/* line 157, ../../frontend/scss/search/_filter.scss */
.search-hfilter .item.selected {
  color: #005580;
  font-weight: 700;
}

@media (max-width: 767px) {
  /* line 165, ../../frontend/scss/search/_filter.scss */
  .left-column {
    position: absolute;
    padding-top: 15px;
    padding-bottom: 15px;
    top: -17px;
    left: -300px;
    z-index: 1000;
    box-shadow: 0 0 12px #333;
    background: rgba(255, 255, 255, 0.94);
    transition: left linear 200ms;
  }
  /* line 178, ../../frontend/scss/search/_filter.scss */
  .left-column.opened {
    left: 0;
  }
  /* line 182, ../../frontend/scss/search/_filter.scss */
  .left-column .close-button {
    right: 10px;
    top: 5px;
  }
  /* line 187, ../../frontend/scss/search/_filter.scss */
  .left-column .top-btn-block {
    padding-bottom: 10px;
  }
}

/* line 194, ../../frontend/scss/search/_filter.scss */
.filters h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  height: 20px;
}
/* line 202, ../../frontend/scss/search/_filter.scss */
.filters .clear-filter {
  font-size: 13px;
  padding-left: 10px;
}
/* line 207, ../../frontend/scss/search/_filter.scss */
.filters .facets {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
}
/* line 213, ../../frontend/scss/search/_filter.scss */
.filters .counts {
  color: #999;
  font-size: 12px;
}
/* line 218, ../../frontend/scss/search/_filter.scss */
.filters .label-customize {
  color: #777;
  font-size: 14px;
}
/* line 223, ../../frontend/scss/search/_filter.scss */
.filters .facet-block {
  margin-bottom: 25px;
}
/* line 227, ../../frontend/scss/search/_filter.scss */
.filters .facet-item {
  font-size: 15px;
  line-height: 1.4;
}
/* line 231, ../../frontend/scss/search/_filter.scss */
.filters .facet-item a {
  color: #0088CC;
}
/* line 234, ../../frontend/scss/search/_filter.scss */
.filters .facet-item a.selected {
  font-weight: bold;
  color: #005580;
}
/* line 239, ../../frontend/scss/search/_filter.scss */
.filters .facet-item a:hover {
  color: #005580;
}
/* line 245, ../../frontend/scss/search/_filter.scss */
.filters .custom {
  margin-top: 2px;
  height: 24px;
  line-height: 24px;
}
/* line 250, ../../frontend/scss/search/_filter.scss */
.filters .custom input[type="text"] {
  font-size: 12px;
  width: 30px;
  height: 21px;
  padding: 2px 3px;
}
/* line 256, ../../frontend/scss/search/_filter.scss */
.filters .custom input[type="text"]:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 3px rgba(82, 168, 236, 0.6);
}
@media (max-width: 767px) {
  /* line 250, ../../frontend/scss/search/_filter.scss */
  .filters .custom input[type="text"] {
    display: inline-block;
  }
}
/* line 265, ../../frontend/scss/search/_filter.scss */
.filters .custom .error input[type="text"]:focus {
  box-shadow: none;
}
/* line 269, ../../frontend/scss/search/_filter.scss */
.filters .custom .apply {
  font-size: 12px;
}
/* line 276, ../../frontend/scss/search/_filter.scss */
.filters .custom .controls {
  display: none;
}
/* line 281, ../../frontend/scss/search/_filter.scss */
.filters .custom:hover .label-customize, .filters .custom.dirty .label-customize {
  display: none;
}
/* line 285, ../../frontend/scss/search/_filter.scss */
.filters .custom:hover .controls, .filters .custom.dirty .controls {
  display: inline;
}

/* line 2, ../../frontend/scss/search/_mine.scss */
.mine-nav .count {
  font-size: 10px;
  color: #888;
}
/* line 8, ../../frontend/scss/search/_mine.scss */
.mine-nav .active .count {
  color: #DDD;
}

/* line 15, ../../frontend/scss/search/_mine.scss */
.mine-page > .col-sm-3 {
  width: 242px;
}
@media (min-width: 1200px) {
  /* line 19, ../../frontend/scss/search/_mine.scss */
  .mine-page > .col-sm-9 {
    width: 928px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 19, ../../frontend/scss/search/_mine.scss */
  .mine-page > .col-sm-9 {
    width: 508px;
  }
}
/* line 29, ../../frontend/scss/search/_mine.scss */
.mine-page .list-group-item {
  padding: 7px 13px;
  font-size: 13px;
  /*
  &.active, &.active:hover, &.active:focus {
      background-color: darken($main-bg, 20%);
      border-color: darken($main-bg, 20%);
  }
  */
}
/* line 33, ../../frontend/scss/search/_mine.scss */
.mine-page .list-group-item .badge {
  font-size: 11px;
  margin-top: 1px;
}
/* line 46, ../../frontend/scss/search/_mine.scss */
.mine-page h3 {
  margin-top: 28px;
  font-size: 16px;
  font-family: "Open Sans", "Heiti TC", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
}

/* line 1, ../../frontend/scss/search/_peak.scss */
.peak {
  font-family: "Open Sans", "Heiti TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.96);
  width: 220px;
  overflow: hidden;
  z-index: 2;
  -webkit-transform: scale3d(1, 1, 1);
}
/* line 10, ../../frontend/scss/search/_peak.scss */
.peak h2 {
  margin-top: 0;
  font-size: 16px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* line 20, ../../frontend/scss/search/_peak.scss */
.peak h3 {
  font-size: 14px;
  line-height: 1.3;
}
/* line 25, ../../frontend/scss/search/_peak.scss */
.peak .info-item {
  padding: 5px 5px 2px 0;
  border-bottom: 1px solid #DDD;
  text-align: right;
}
/* line 30, ../../frontend/scss/search/_peak.scss */
.peak .info-item .tag {
  font-size: 13px;
  color: #888;
  padding-right: 5px;
  float: left;
  padding-top: 5px;
}
/* line 39, ../../frontend/scss/search/_peak.scss */
.peak .value {
  font-size: 20px;
  color: #454545;
}
/* line 44, ../../frontend/scss/search/_peak.scss */
.peak .unit {
  font-size: 12px;
  color: #888;
  padding-right: 5px;
  padding-left: 2px;
}
/* line 52, ../../frontend/scss/search/_peak.scss */
.peak form .buttons {
  text-align: right;
}
/* line 57, ../../frontend/scss/search/_peak.scss */
.peak .map {
  width: 220px;
  height: 185px;
}
/* line 62, ../../frontend/scss/search/_peak.scss */
.peak .map img {
  max-width: none;
}

/* line 8, ../../frontend/scss/search/_main.scss */
.noscroll {
  overflow: hidden;
  height: 100%;
}

/* line 13, ../../frontend/scss/search/_main.scss */
.search-v2 body {
  padding-top: 43px;
}

/* line 18, ../../frontend/scss/search/_main.scss */
.search-page .info-row {
  padding: 4px 0;
  line-height: 20px;
}
/* line 22, ../../frontend/scss/search/_main.scss */
.search-page .info-row .news-block {
  padding-left: 8px;
}
/* line 24, ../../frontend/scss/search/_main.scss */
.search-page .info-row .news-block .news-item {
  font-size: 13px;
}
/* line 29, ../../frontend/scss/search/_main.scss */
.search-page .info-row .fanpage {
  font-size: 12px;
  line-height: 12px;
  padding: 3px 5px 3px;
  background-color: #4562b4;
}
/* line 36, ../../frontend/scss/search/_main.scss */
.search-page .info-row .fb-wrapper {
  display: inline-block;
  position: relative;
  z-index: 101;
}
/* line 41, ../../frontend/scss/search/_main.scss */
.search-page .info-row .fb-wrapper iframe {
  position: absolute;
  top: 2px;
}
/* line 47, ../../frontend/scss/search/_main.scss */
.search-page .info-row .item {
  display: inline-block;
  padding-left: 8px;
}
/* line 52, ../../frontend/scss/search/_main.scss */
.search-page .info-row .email {
  font-size: 13px;
}
/* line 57, ../../frontend/scss/search/_main.scss */
.search-page .search-bar {
  width: 50%;
}
/* line 61, ../../frontend/scss/search/_main.scss */
.search-page .full-row {
  padding-left: 0;
  padding-right: 0;
}
/* line 67, ../../frontend/scss/search/_main.scss */
.search-page .search-first-line .btn {
  border-radius: 0;
}
/* line 72, ../../frontend/scss/search/_main.scss */
.search-page .filters-block {
  padding-top: 6px;
  padding-left: 6px;
  height: 56px;
  background-color: #EEE;
  border-bottom: 1px solid #AAA;
}
/* line 81, ../../frontend/scss/search/_main.scss */
.search-page .filter-control {
  min-width: 145px;
  position: relative;
  float: left;
  margin-right: 1px;
}
/* line 87, ../../frontend/scss/search/_main.scss */
.search-page .filter-control:first-child .display-block {
  border-left: none;
}
/* line 91, ../../frontend/scss/search/_main.scss */
.search-page .filter-control > a {
  display: block;
  background: transparent;
  min-width: 100%;
}
/* line 98, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .display-block {
  position: relative;
  padding: 3px 15px 3px;
  border-left: 1px solid #BBB;
}
/* line 103, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .display-block .value-block {
  max-width: 91%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  display: inline-block;
}
/* line 112, ../../frontend/scss/search/_main.scss */
.search-page .filter-control.opened > .display-block {
  z-index: 103;
  background: whitesmoke;
}
/* line 117, ../../frontend/scss/search/_main.scss */
.search-page .filter-control.opened .filter-menu {
  display: block;
}
/* line 121, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .label-block {
  font-size: 12px;
  color: #888;
}
/* line 126, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .unit {
  font-size: 80%;
}
/* line 130, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .value-block {
  float: left;
  margin-right: 3px;
}
/* line 134, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .value-block .display {
  padding-right: 3px;
}
/* line 137, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .value-block .display.last {
  padding-right: 0;
}
/* line 143, ../../frontend/scss/search/_main.scss */
.search-page .filter-control .caret-block {
  height: 21px;
  float: left;
}
/* line 151, ../../frontend/scss/search/_main.scss */
.search-page .filter-ff-r.filter-control {
  min-width: 110px;
}
/* line 157, ../../frontend/scss/search/_main.scss */
.search-page .filter-ff-r .filter-menu .filter-range-custom .form-control {
  width: 35px;
}
/* line 162, ../../frontend/scss/search/_main.scss */
.search-page .filter-ff-r .filter-menu.range {
  min-width: 110px;
}
/* line 169, ../../frontend/scss/search/_main.scss */
.search-page .filter-other {
  width: 220px;
}
/* line 172, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-menu {
  min-width: 362px;
  right: 0;
  left: auto;
}
/* line 177, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-menu .anchor {
  position: relative;
  min-height: 249px;
  overflow-y: hidden;
}
/* line 183, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-menu .filter-other-top-deco {
  width: 100%;
  padding: 2px 6px;
}
/* line 190, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-other-title {
  font-size: 15px;
  margin-bottom: 3px;
  margin-top: 6px;
}
/* line 196, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-other-block {
  width: 111px;
  float: left;
  border-left: 1px solid #CCC;
}
/* line 201, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-other-block:first-child {
  border-left-color: transparent;
}
/* line 206, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-other-block .filter-range-custom .form-control {
  width: 35px;
}
/* line 211, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-other-block.range {
  min-width: 110px;
}
/* line 216, ../../frontend/scss/search/_main.scss */
.search-page .filter-other .filter-ff-or {
  width: 140px;
  height: 274px;
}
/* line 222, ../../frontend/scss/search/_main.scss */
.search-page .range-overlay-block {
  position: relative;
  opacity: 1;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
/* line 228, ../../frontend/scss/search/_main.scss */
.search-page .range-overlay-block.disappear {
  opacity: 0;
}
/* line 233, ../../frontend/scss/search/_main.scss */
.search-page .pin {
  position: absolute;
}
/* line 236, ../../frontend/scss/search/_main.scss */
.search-page .pin .block {
  position: absolute;
  top: -22px;
  left: -9px;
  padding: 1px 3px;
  border: 1px solid #259ecc;
  border-radius: 2px;
  font-size: 12px;
  background-color: #c8e9f5;
  font-family: "Open Sans", "HeiTi TC", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
  cursor: pointer;
  border-bottom-width: 2px;
}
/* line 254, ../../frontend/scss/search/_main.scss */
.search-page .pin .block.highlight {
  background-color: #1d7ca1;
  color: #FFF;
  z-index: 500;
  border: 1px solid #165b75;
  border-bottom-width: 2px;
}
/* line 261, ../../frontend/scss/search/_main.scss */
.search-page .pin .block.highlight .tri {
  border-top: 5px solid #165b75;
}
/* line 267, ../../frontend/scss/search/_main.scss */
.search-page .pin:hover {
  z-index: 500;
}
/* line 271, ../../frontend/scss/search/_main.scss */
.search-page .pin .unit {
  font-size: 11px;
  font-weight: normal;
}
/* line 276, ../../frontend/scss/search/_main.scss */
.search-page .pin .tri {
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #259ecc;
  top: 17px;
  left: 3px;
}
/* line 285, ../../frontend/scss/search/_main.scss */
.search-page .pin.pinned .map-peak {
  background-color: #FFF;
}
/* line 288, ../../frontend/scss/search/_main.scss */
.search-page .pin.pinned .map-peak .close-block {
  display: block;
}
/* line 293, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak {
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.85);
  font-family: "Open Sans", "HeiTi TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
  position: absolute;
  left: -16px;
  bottom: 24px;
  width: 400px;
  z-index: 50;
  font-size: 15px;
}
/* line 304, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .close-block {
  display: none;
  position: absolute;
  right: 5px;
  top: 1px;
}
/* line 311, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .layout-word, .search-page .pin .map-peak .unit {
  color: #777;
  font-size: 12px;
  margin-left: 1px;
  margin-right: 5px;
}
/* line 318, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .price {
  color: red;
  font-weight: 700;
}
/* line 323, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .url {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
}
/* line 331, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* line 338, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .address {
  font-size: 12px;
}
/* line 342, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .peak-right {
  width: 96%;
}
/* line 346, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .info {
  line-height: 21px;
}
/* line 350, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .peak-thumbnail {
  padding-right: 4px;
  float: left;
}
/* line 354, ../../frontend/scss/search/_main.scss */
.search-page .pin .map-peak .peak-thumbnail .thumbnail {
  max-width: 160px;
  max-height: 120px;
  margin-bottom: 0;
}
/* line 363, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu {
  display: none;
  position: absolute;
  left: 1px;
  z-index: 102;
  background: rgba(245, 245, 245, 0.95);
  min-width: 100%;
  box-shadow: 0 0px 5px #AAA;
  overflow-y: hidden;
  -webkit-transition-duration: 100ms;
  -moz-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-property: height;
  -moz-transition-property: height;
  -o-transition-property: height;
  transition-property: height;
}
/* line 378, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .custom-list {
  height: 100%;
}
/* line 381, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .custom-list .custom-to {
  text-align: right;
}
/* line 385, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .custom-list .selected {
  font-weight: 700;
}
/* line 390, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .encapsulate {
  -webkit-transition-duration: 100ms;
  -moz-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  transition-property: top;
  position: absolute;
  top: 0;
  background: rgba(245, 245, 245, 0.95);
  padding-bottom: 10px;
}
/* line 401, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .section {
  padding: 0 15px;
}
/* line 406, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .form-inline .control-label {
  font-size: 12px;
}
/* line 410, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .form-inline.section {
  padding: 0 10px;
}
/* line 416, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu input::-webkit-outer-spin-button,
.search-page .filter-menu input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* line 423, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-range-custom::-webkit-outer-spin-button, .search-page .filter-menu .filter-range-custom::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* line 428, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-range-custom .form-control {
  width: 52px;
  padding: 5px;
  height: 28px;
  font-size: 13px;
}
/* line 435, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-range-custom h5 {
  padding: 6px 15px;
  background-color: rgba(230, 230, 230, 0.95);
  font-size: 13px;
}
/* line 442, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu.range {
  min-width: 144px;
}
/* line 447, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-option-group.range {
  padding-left: 0;
}
/* line 451, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-option-group .selected {
  font-weight: 700;
}
/* line 455, ../../frontend/scss/search/_main.scss */
.search-page .filter-menu .filter-option-group.inner {
  padding-left: 40px;
}
/* line 463, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block {
  position: relative;
  float: left;
}
/* line 467, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .map-message {
  height: 23px;
  position: absolute;
  top: 27px;
  right: -500px;
  background: rgba(90, 90, 90, 0.9);
  color: #FFF;
  z-index: 99;
  padding: 0 8px;
  visibility: hidden;
  -webkit-transition-property: right;
  -moz-transition-property: right;
  -o-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
}
/* line 481, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .map-message.display {
  right: 0;
  visibility: visible;
}
/* line 487, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block {
  position: absolute;
  height: 25px;
  background: rgba(90, 90, 90, 0.87);
  color: #EEE;
  width: 100%;
  z-index: 100;
  padding-top: 1px;
  padding-left: 8px;
}
/* line 497, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block .jumper-label {
  font-size: 13px;
}
/* line 501, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block .map-loading {
  position: absolute;
  right: 0;
  top: 0;
  background-color: red;
  color: #FFF;
  padding: 1px 8px;
  height: 24px;
}
/* line 511, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block ul.list {
  display: inline-block;
}
/* line 513, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block ul.list > li {
  display: inline-block;
}
/* line 519, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li {
  position: relative;
}
/* line 522, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li > a {
  padding: 3px 8px;
  color: #EEE;
}
/* line 526, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li > a:hover, .search-page .main .map-block .jumper-block > ul > li > a.opened {
  color: #FFF;
  background-color: rgba(90, 90, 90, 0.7);
}
/* line 532, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li .jump-menu {
  position: absolute;
  z-index: 101;
  color: #444;
  top: 24px;
}
/* line 540, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li .top li, .search-page .main .map-block .jumper-block > ul > li .level2 li {
  padding: 8px 0;
}
/* line 544, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li .top a, .search-page .main .map-block .jumper-block > ul > li .level2 a {
  padding: 8px;
  color: #000;
  background: rgba(255, 255, 255, 0.85);
}
/* line 549, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li .top a:hover, .search-page .main .map-block .jumper-block > ul > li .level2 a:hover {
  background-color: #e6e6e6;
}
/* line 553, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block > ul > li .top a.selected, .search-page .main .map-block .jumper-block > ul > li .level2 a.selected {
  background-color: #c8c8c8;
}
/* line 561, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block .jump-menu-district {
  width: 970px;
}
/* line 565, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .jumper-block .jump-menu-mrt {
  width: 700px;
}
/* line 570, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls {
  position: absolute;
  right: 12px;
  bottom: 23px;
  z-index: 300;
  color: #FFF;
}
/* line 577, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .control-btn {
  display: block;
  width: 38px;
  height: 38px;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 4px;
  margin: 1px 0;
  color: #EEE;
  text-align: center;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
/* line 588, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .control-btn:hover {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.65);
}
/* line 594, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .zoom-in, .search-page .main .map-block .custom-controls .zoom-out {
  font-size: 20px;
  line-height: 23px;
}
/* line 599, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-label {
  font-size: 12px;
  line-height: 30px;
}
/* line 604, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-range-enabled {
  line-height: 30px;
  font-size: 18px;
}
/* line 607, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-range-enabled.active {
  color: red;
}
/* line 611, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-range-enabled .glyphicon {
  width: 18px;
  height: 18px;
}
/* line 617, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-range {
  font-size: 12px;
  line-height: 30px;
  color: #AAA;
}
/* line 622, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .toggle-range.active {
  color: #FFF;
}
/* line 627, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .menu {
  position: relative;
}
/* line 631, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .menu:hover .sidemenu {
  display: block;
}
/* line 636, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .menu .sidemenu {
  display: none;
  position: absolute;
  right: 38px;
  top: -1px;
  width: 200px;
  text-align: right;
}
/* line 644, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .menu .sidemenu a {
  display: inline-block;
  margin-right: 1px;
  font-size: 12px;
}
/* line 649, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .custom-controls .menu .sidemenu a.selected {
  color: #FFF;
}
/* line 657, ../../frontend/scss/search/_main.scss */
.search-page .main .map-block .group {
  margin-bottom: 6px;
}
/* line 662, ../../frontend/scss/search/_main.scss */
.search-page .main .map {
  width: auto;
  background-color: #CCC;
  height: 500px;
}
/* line 668, ../../frontend/scss/search/_main.scss */
.search-page .main .result-block {
  position: absolute;
  right: 0;
  width: 28%;
  background-color: #AAA;
  height: 100%;
  z-index: 100;
  box-shadow: -7px 3px 9px -3px #555555;
}
/* line 677, ../../frontend/scss/search/_main.scss */
.search-page .main .result-block .result-panel {
  padding: 8px;
  background-color: #CFCFCF;
}
/* line 682, ../../frontend/scss/search/_main.scss */
.search-page .main .result-block .pagination-panel {
  background-color: #FFF;
}
/* line 687, ../../frontend/scss/search/_main.scss */
.search-page .main .results {
  overflow-y: scroll;
}
/* line 690, ../../frontend/scss/search/_main.scss */
.search-page .main .results .load-mask {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  opacity: 0;
  z-index: 1;
  position: absolute;
  pointer-events: none;
  transition-duration: 250ms;
  transition-property: opacity;
}
/* line 704, ../../frontend/scss/search/_main.scss */
.search-page .main .results.result-loading .load-mask {
  opacity: 0.7;
}
/* line 709, ../../frontend/scss/search/_main.scss */
.search-page .main .results .item {
  background-color: #fdfdfd;
  padding: 16px 0;
  margin-bottom: 1px;
  position: relative;
                /*
                .title {
                    font-size: 16px;
                    font-family: "Open Sans", "Heiti TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;

                    a {
                        display: block;
                    }

                    .url {
                        font-size: 12px;
                    }
                }
                */
  margin-bottom: 0;
  min-height: 120px;
}
/* line 715, ../../frontend/scss/search/_main.scss */
.search-page .main .results .item .cover-block {
  padding-right: 0;
}
/* line 734, ../../frontend/scss/search/_main.scss */
.search-page .main .results .item .info.row {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

/* line 749, ../../frontend/scss/search/_main.scss */
.search-block {
  padding-bottom: 15px;
  position: relative;
}
/* line 754, ../../frontend/scss/search/_main.scss */
.search-block form .col-sm-8 {
  padding-right: 0;
}
@media (max-width: 767px) {
  /* line 754, ../../frontend/scss/search/_main.scss */
  .search-block form .col-sm-8 {
    padding-right: 15px;
  }
}

/* line 764, ../../frontend/scss/search/_main.scss */
.search-page .metadata {
  position: fixed;
  width: 600px;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #000;
  left: 20px;
  top: 20px;
  padding: 20px;
  overflow: scroll;
}
/* line 776, ../../frontend/scss/search/_main.scss */
.search-page > .col-sm-3 {
  width: 242px;
  overflow: visible;
}
@media (min-width: 1200px) {
  /* line 781, ../../frontend/scss/search/_main.scss */
  .search-page > .col-sm-9 {
    width: 928px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 781, ../../frontend/scss/search/_main.scss */
  .search-page > .col-sm-9 {
    width: 508px;
  }
}

/* line 792, ../../frontend/scss/search/_main.scss */
.search-form {
  position: relative;
  margin-bottom: 0;
}

/* line 797, ../../frontend/scss/search/_main.scss */
.search-syntax-container {
  position: absolute;
  background-color: rgba(250, 250, 250, 0.98);
  padding: 10px;
  top: 35px;
  width: 522px;
  z-index: 200;
  border: 1px solid #999;
  border-top: none;
}
/* line 807, ../../frontend/scss/search/_main.scss */
.lt-ie9 .search-syntax-container {
  background-color: #FFF;
}
/* line 811, ../../frontend/scss/search/_main.scss */
.search-syntax-container h4 {
  margin-top: 2px;
}
/* line 815, ../../frontend/scss/search/_main.scss */
.search-syntax-container h5 {
  font-size: 16.5px;
  font-weight: normal;
  margin-bottom: 2px;
}
/* line 821, ../../frontend/scss/search/_main.scss */
.search-syntax-container .row {
  margin-left: -15px;
  margin-bottom: 12px;
}
/* line 826, ../../frontend/scss/search/_main.scss */
.search-syntax-container .type {
  float: left;
  width: 250px;
  margin-left: 15px;
}
/* line 832, ../../frontend/scss/search/_main.scss */
.search-syntax-container .example {
  font-size: 11px;
  color: #999;
}
/* line 837, ../../frontend/scss/search/_main.scss */
.search-syntax-container .identifier {
  font-size: 13.5px;
}
/* line 841, ../../frontend/scss/search/_main.scss */
.search-syntax-container dl {
  margin: 0 0 5px;
}
/* line 845, ../../frontend/scss/search/_main.scss */
.search-syntax-container dt {
  float: left;
  font-weight: normal;
  font-family: monospace;
  width: 88px;
}
/* line 853, ../../frontend/scss/search/_main.scss */
.search-syntax-container dd {
  margin-left: 90px;
  color: #777;
}
/* line 859, ../../frontend/scss/search/_main.scss */
.search-syntax-container .syntax {
  margin-bottom: 0;
}
/* line 862, ../../frontend/scss/search/_main.scss */
.search-syntax-container .syntax i {
  color: #888;
  font-style: normal;
}

/* line 869, ../../frontend/scss/search/_main.scss */
.presets {
  font-size: 11px;
  padding-top: 3px;
}
/* line 873, ../../frontend/scss/search/_main.scss */
.presets ul {
  display: inline;
  margin-left: 0;
}
/* line 877, ../../frontend/scss/search/_main.scss */
.presets ul li {
  display: inline;
  padding-right: 4px;
  font-size: 13px;
  padding: 0px 2px;
}
/* line 886, ../../frontend/scss/search/_main.scss */
.presets .item:hover {
  background-color: #EFEFEF;
}
/* line 889, ../../frontend/scss/search/_main.scss */
.presets .item:hover .remove {
  visibility: visible;
}
/* line 895, ../../frontend/scss/search/_main.scss */
.presets .remove {
  visibility: hidden;
}

/* line 901, ../../frontend/scss/search/_main.scss */
.sortby ul {
  list-style-type: none;
  margin-left: 0;
  float: right;
}
/* line 907, ../../frontend/scss/search/_main.scss */
.sortby li {
  float: left;
  padding-left: 20px;
}
/* line 912, ../../frontend/scss/search/_main.scss */
.sortby a.selected {
  border-bottom: 3px solid #0088CC;
}
@media (max-width: 767px) {
  /* line 917, ../../frontend/scss/search/_main.scss */
  .sortby ul {
    float: left;
  }
  /* line 920, ../../frontend/scss/search/_main.scss */
  .sortby ul li {
    padding-left: 0;
    padding-right: 20px;
  }
}

/* line 928, ../../frontend/scss/search/_main.scss */
.property-item {
  font-family: "Open Sans", sans-serif;
  padding: 20px 0;
  border-bottom: 1px solid #EFEFEF;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
/* line 936, ../../frontend/scss/search/_main.scss */
.property-item .col-left {
  width: 155px;
}
/* line 940, ../../frontend/scss/search/_main.scss */
.property-item .col-right {
  padding-left: 0;
}
@media (min-width: 1200px) {
  /* line 940, ../../frontend/scss/search/_main.scss */
  .property-item .col-right {
    width: 773px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 940, ../../frontend/scss/search/_main.scss */
  .property-item .col-right {
    width: 572px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 940, ../../frontend/scss/search/_main.scss */
  .property-item .col-right {
    width: 353px;
  }
}
@media (max-width: 767px) {
  /* line 940, ../../frontend/scss/search/_main.scss */
  .property-item .col-right {
    padding-left: 15px;
  }
}
/* line 960, ../../frontend/scss/search/_main.scss */
.property-item:last-child {
  margin-bottom: 20px;
}
/* line 964, ../../frontend/scss/search/_main.scss */
.property-item.notinterested {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
/* line 969, ../../frontend/scss/search/_main.scss */
.property-item.viewed .title-block a {
  color: #a259c5;
}
/* line 972, ../../frontend/scss/search/_main.scss */
.property-item.viewed .title-block a:hover {
  color: #6e00a2;
}
/* line 978, ../../frontend/scss/search/_main.scss */
.property-item .glyphicon-bookmark {
  font-size: 11px;
  width: 10px;
  top: -2px;
}
/* line 984, ../../frontend/scss/search/_main.scss */
.property-item .url {
  font-size: 11px;
}
/* line 988, ../../frontend/scss/search/_main.scss */
.property-item .title-block {
  font-size: 16px;
  padding: 5px 0;
  font-family: "Open Sans", "Heiti TC Light", "å¾®è»Ÿæ­£é»‘é«”", sans-serif;
}
/* line 993, ../../frontend/scss/search/_main.scss */
.property-item .title-block .title {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* line 1001, ../../frontend/scss/search/_main.scss */
.property-item .title-block .url {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* line 1009, ../../frontend/scss/search/_main.scss */
.property-item .title-block a {
  display: block;
}
/* line 1014, ../../frontend/scss/search/_main.scss */
.property-item .address {
  font-size: 13px;
}
/* line 1018, ../../frontend/scss/search/_main.scss */
.property-item .status {
  color: #AAA;
  font-size: 12px;
}
/* line 1024, ../../frontend/scss/search/_main.scss */
.property-item .status-tags {
  padding-left: 20px;
}
/* line 1027, ../../frontend/scss/search/_main.scss */
.property-item .status-tags a {
  color: #656565;
}
/* line 1030, ../../frontend/scss/search/_main.scss */
.property-item .status-tags a:hover {
  color: #333;
}
/* line 1036, ../../frontend/scss/search/_main.scss */
.property-item .info {
  text-align: right;
  margin-top: 5px;
}
@media (min-width: 1200px) {
  /* line 1036, ../../frontend/scss/search/_main.scss */
  .property-item .info {
    padding-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 1036, ../../frontend/scss/search/_main.scss */
  .property-item .info {
    padding-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 1036, ../../frontend/scss/search/_main.scss */
  .property-item .info {
    width: 353px;
    text-align: left;
  }
  /* line 1051, ../../frontend/scss/search/_main.scss */
  .property-item .info .value {
    display: inline !important;
  }
  /* line 1055, ../../frontend/scss/search/_main.scss */
  .property-item .info .unit {
    margin-left: 0 !important;
  }
}
@media (max-width: 767px) {
  /* line 1036, ../../frontend/scss/search/_main.scss */
  .property-item .info {
    text-align: left;
  }
  /* line 1063, ../../frontend/scss/search/_main.scss */
  .property-item .info .unit {
    margin-left: 0 !important;
  }
}
/* line 1068, ../../frontend/scss/search/_main.scss */
.property-item .info .parking-type {
  font-size: 12px;
  color: #777;
}
/* line 1073, ../../frontend/scss/search/_main.scss */
.property-item .info .value {
  font-size: 16px;
  display: block;
  line-height: 1;
}
/* line 1079, ../../frontend/scss/search/_main.scss */
.property-item .info .price.value {
  font-weight: 700;
  font-size: 18px;
  color: #FF3930;
}
/* line 1085, ../../frontend/scss/search/_main.scss */
.property-item .info .unit {
  margin-left: 3px;
  font-size: 13px;
  color: #888;
}
/* line 1091, ../../frontend/scss/search/_main.scss */
.property-item .info .parking-included {
  font-size: 13px;
  margin-left: 4px;
}
/* line 1098, ../../frontend/scss/search/_main.scss */
.property-item .layout .layout-word {
  font-size: 12px;
  color: #777;
  margin-right: 5px;
  margin-left: 1px;
}
/* line 1106, ../../frontend/scss/search/_main.scss */
.property-item .cover {
  /*
  display: block;
  float: left;
  */
  height: 90px;
}
/* line 1114, ../../frontend/scss/search/_main.scss */
.property-item .cover img {
  width: 100%;
}
/* line 1120, ../../frontend/scss/search/_main.scss */
.property-item .actions {
  clear: both;
  margin: 5px 0;
  // .btn-toolbar-margin: 0;
}
/* line 1129, ../../frontend/scss/search/_main.scss */
.property-item .actions .btn-group:first-child {
  margin-left: 0;
}
/* line 1133, ../../frontend/scss/search/_main.scss */
.property-item .actions .btn {
  background-color: #FFF;
  background-image: none;
  color: #333;
  font-size: 12px;
}
/* line 1139, ../../frontend/scss/search/_main.scss */
.property-item .actions .btn:hover {
  background-color: #EEE;
}
/* line 1145, ../../frontend/scss/search/_main.scss */
.property-item .label {
  padding: 0.1em 0.6em;
}
/* line 1149, ../../frontend/scss/search/_main.scss */
.property-item .show-hover {
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 150ms;
  -moz-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
/* line 1157, ../../frontend/scss/search/_main.scss */
.property-item:hover .show-hover {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* line 1164, ../../frontend/scss/search/_main.scss */
.property-item .memos .controls {
  display: none;
  list-style-type: none;
  margin: 0 0 0 10px;
}
/* line 1169, ../../frontend/scss/search/_main.scss */
.property-item .memos .controls li {
  display: inline;
  padding-right: 5px;
}
/* line 1175, ../../frontend/scss/search/_main.scss */
.property-item .memos .edit .controls {
  display: block;
}
/* line 1179, ../../frontend/scss/search/_main.scss */
.property-item .memos textarea {
  width: 100%;
  margin-bottom: 0;
  font-size: 13px;
}
/* line 1185, ../../frontend/scss/search/_main.scss */
.property-item .memos .memo-line {
  height: 1px;
  width: 230px;
  border-top: dotted 1px #AAA;
  margin-top: 12px;
  padding-top: 4px;
}
/* line 1194, ../../frontend/scss/search/_main.scss */
.property-item .memo-item {
  margin-top: 3px;
  font-size: 13px;
}
/* line 1198, ../../frontend/scss/search/_main.scss */
.property-item .memo-item .timestamp {
  font-size: 12px;
  color: #888;
}
/* line 1203, ../../frontend/scss/search/_main.scss */
.property-item .memo-item:hover .controls {
  display: inline;
}

/* line 1209, ../../frontend/scss/search/_main.scss */
.jump-page {
  font-size: 12px;
}
/* line 1213, ../../frontend/scss/search/_main.scss */
.jump-page:hover .hover-display, .jump-page.dirty .hover-display {
  opacity: 1;
}
/* line 1217, ../../frontend/scss/search/_main.scss */
.jump-page .hover-display {
  opacity: 0;
}
/* line 1221, ../../frontend/scss/search/_main.scss */
.jump-page form {
  margin-bottom: 0;
}
/* line 1225, ../../frontend/scss/search/_main.scss */
.jump-page input {
  font-size: 12px;
  padding: 2px 3px;
  width: 30px !important;
  height: 24px;
  margin-bottom: 0;
}
/* line 1232, ../../frontend/scss/search/_main.scss */
.jump-page input:focus {
  box-shadow: none;
}
@media (max-width: 767px) {
  /* line 1225, ../../frontend/scss/search/_main.scss */
  .jump-page input {
    display: inline-block;
  }
}
/* line 1241, ../../frontend/scss/search/_main.scss */
.jump-page button {
  font-size: 12px;
  padding: 2px 7px;
}

/* line 1247, ../../frontend/scss/search/_main.scss */
.pagination {
  margin-top: 0;
  margin-bottom: 0;
}

/* line 1252, ../../frontend/scss/search/_main.scss */
.undo-block {
  width: 100%;
  height: 42px;
  background-color: #FFF;
  position: fixed;
  left: 0;
  top: -42px;
  z-index: 2030;
  text-align: center;
}
/* line 1262, ../../frontend/scss/search/_main.scss */
.undo-block .message-block {
  padding-top: 12px;
}
/* line 1266, ../../frontend/scss/search/_main.scss */
.undo-block .close-block {
  top: 4px;
  right: 15px;
  position: absolute;
  font-size: 20px;
}

/* line 15, ../../frontend/scss/main.scss */
.label-light {
  background-color: #EEE;
  color: #777;
  font-weight: normal;
}

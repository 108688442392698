// 預設變數覆蓋需要在導入Bootstrap之前
// $primary: #e412a5;
// $secondary: #131010;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

// 其它要導入覆蓋掉原本的預設bootstrap樣式要放在這下面

body {
  font-family: '微軟正黑體', 'Noto Sans', '儷黑 Pro', '文泉驛正黑',
    'WenQuanYi Zen Hei', 'LiHei Pro', 'Microsoft JhengHei', Arial, sans-serif;
}

main > .container {
  // padding: 75px 15px 0;
  padding: 0px 15px 0;
}

.react-icons {
  vertical-align: middle;
}

.footer {
  background-color: #f5f5f5;
}

.navbar {
  // height: 75px;
}

.sticky-offset {
  top: 63px;
}

.sticky-offset-table {
  top: 123px;
}

// .teststicky {
//   color: red;
//   position: fixed;
//   position: -webkit-sticky;
//   top: 123px;
// }

.w-5 {
  width: 5%;
}

.w-7-5 {
  width: 7.5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

/* change the background color */
.bg-custom {
  background-color: #ffffff;
}
/* change the brand and text color */
.bg-custom .navbar-brand,
.bg-custom .navbar-text {
  color: #000;
}
/* change the link color */
.bg-custom .navbar-nav .nav-link {
  color: #000;
}

.bg-custom .navbar-nav .nav-link:hover {
  color: #e8131e;
}

/* change the color of active or hovered links */
.bg-custom .nav-item.active .nav-link,
.bg-custom .nav-item:focus .nav-link,
.bg-custom .nav-item:hover .nav-link {
  color: #e8131e;
}

// TICKER

/* WRAPPER */
.tickerv-wrap {
  background: #fff;
  box-sizing: content-box;
  height: 24px; /* Take note of this */
  overflow: hidden; /* Hide scrollbars */
  padding: 0px;
}

/* TICKER ANIMATION */
@keyframes tickerv {
  0% {
    margin-top: 0;
  }
  /* Quite literally -ve height of wrapper */
  25% {
    margin-top: -26px;
  } /* 1 X 25 px */
  50% {
    margin-top: -52px;
  } /* 2 X 25 px */
  75% {
    margin-top: -78px;
  } /* 3 X 25 px */
  100% {
    margin-top: 0;
  } /* Back to first line */
}
.tickerv-wrap ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  animation-name: tickerv; /* Loop through items */
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0.5, 0);
}
.tickerv-wrap ul:hover {
  /* Pause on mouse hover */
  animation-play-state: paused;
}

/* ITEMS */
.tickerv-wrap ul li {
  font-size: 18px;
  line-height: 26px; /* Same as wrapper height */
}
//TICKER

.btn-brown {
  background-color: purple;
  color: white;
}

.btn-link {
  background-color: #ffffff;
  color: #000;
}

// mixin for button color every line of mrt
@mixin btn-color($title, $index, $color, $text-color) {
  .btn-#{$title}-v#{$index},
  .btn-#{$title}-v#{$index}:hover {
    background-color: $color;
    color: $text-color;
  }

  .btn-#{$title}-v#{$index}:active,
  .btn-#{$title}-v#{$index}:target {
    color: white;
    @if ($text-color == white) {
      /* Do something if $p is set to $default */
      color: white;
    } @else {
      /* Do something else if $p is not $default */
      color: darken($text-color, 10%);
    }
    background-color: $color;
    //border-color: $darkOrange;
  }

  .btn-link-#{$title}-v#{$index} {
    border: 2px solid $color;
    @if ($text-color == white) {
      /* Do something if $p is set to $default */
      color: $color;
    } @else {
      /* Do something else if $p is not $default */
      color: $text-color;
    }
  }

  .btn-link-#{$title}-v#{$index}:hover {
    border: 2px solid darken($color, 10%);
    color: darken($color, 10%);
  }
}

// v1 #c48c31
@include btn-color('tpemrt', 1, #c48c31, white);
// v2 #e3002c
@include btn-color('tpemrt', 2, #e3002c, white);
// v3 #f8b61c
@include btn-color('tpemrt', 3, #f8b61c, white);
// $color-tpemrt-v4: #008659;
@include btn-color('tpemrt', 4, #008659, white);
//$color-tpemrt-v5: #0070bd;
@include btn-color('tpemrt', 5, #0070bd, white);
// tpemrt-v6: #8246AF
@include btn-color('tpemrt', 6, #8246af, white);
// tpemrt-v7: #FFDB00
@include btn-color('tpemrt', 7, #ffdb00, #c48c31);
//// tpemrt-v8: #e5554f
@include btn-color('tpemrt', 8, #e5554f, white);

// tpemrt-future-v1
// v1 #e3002c
@include btn-color('tpemrt-future', 1, #e3002c, white);
// v2: #a1d884
@include btn-color('tpemrt-future', 2, #a1d884, #008659);
// v4: #FFDB00
@include btn-color('tpemrt-future', 4, #ffdb00, #c48c31);
// v8: #25aae1
@include btn-color('tpemrt-future', 8, #25aae1, white);
// v9: #79bce8
@include btn-color('tpemrt-future', 9, #79bce8, white);
// v10: #c3b091
@include btn-color('tpemrt-future', 10, #c3b091, white);
// v11, v12: #e5554f
@include btn-color('tpemrt-future', 11, #e5554f, white);

// tyhmrt-v1: #8246AF
@include btn-color('tyhmrt', 1, #8246af, white);

// tyhmrt-future-v1: #8246AF
@include btn-color('tyhmrt-future', 1, #8246af, white);
//  v2: #ea0437
@include btn-color('tyhmrt-future', 2, #ea0437, white);
//  v3: #62A033
@include btn-color('tyhmrt-future', 3, #62a033, white);

// tccmrt-future-v1: #8EC31C
@include btn-color('tccmrt-future', 1, #8ec31c, white);
// tccmrt-future-v2: #ea0437
@include btn-color('tccmrt-future', 2, #ea0437, white);

// khcmrt-v1: #e20b65
@include btn-color('khcmrt', 1, #e20b65, white);
// khcmrt-v2: #faa73f
@include btn-color('khcmrt', 2, #faa73f, white);

// bigger
input[type='radio'] {
  transform: scale(1.5);
  color: #f7eef0;
}

.form-check-inline {
  margin: 0 1em;
}

.form-check-inline label {
  margin: 0 0.25em;
}

.table th,
.table td {
  vertical-align: middle;
}

.odd-row {
  background: rgba(0, 0, 0, 0.05);
}

tr.summary:hover {
  color: #0070bd;
}

.unit {
  font-size: 14px;
  color: #888;
  white-space: nowrap;
}

.dealzone-year {
  font-size: 15px;
  color: #666;
}

.price {
  font-family: 'Open Sans', sans-serif;
  color: #ed2914;
  font-weight: 600;
}

.btn-list-header {
  color: #428bca;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  vertical-align: middle;
}

a {
  color: #428bca;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #2a6496;
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .date-w {
    width: 25%;
  }

  .address-w {
    width: 75%;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .date-w {
    width: 7.5%;
  }

  .address-w {
    width: 20%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .date-w {
    width: 7.5%;
  }

  .address-w {
    width: 20%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .date-w {
    width: 7.5%;
  }

  .address-w {
    width: 20%;
  }
}

.footer .nav-link {
  padding: 0rem 0.2rem;
}

.footer-item {
  color: #fff;
}

.footer {
  background-color: #0097e0;
  color: #fff;
  padding: 40px 40px 40px 40px;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 30px;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #ef3f48;
}
